import './index.css';
import { createTheme } from '@mui/material/styles';
import {defaultTheme} from "react-admin";
import indigo from '@mui/material/colors/indigo';
import red from '@mui/material/colors/red';

export const theme = createTheme({
    ...defaultTheme,
    palette: {
        secondary: {
            main: '#595959'
        },
        primary: indigo,
        error: red,
        contrastThreshold: 3,
        tonalOffset: 0.2,
    },
    sidebar: {
        /*width: 110,
        closedWidth: 40,*/
    },
});
