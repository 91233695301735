import {useKeycloak} from '@react-keycloak/web'
import jwt_decode from 'jwt-decode'

const useAuthProvider = (clientID) => {
    const {keycloak} = useKeycloak();
    return ({
        login: () => {
            console.log('login');
            return keycloak.login();
        },
        checkError: () => {
            console.log('checkError');
            return Promise.resolve();
        },
        checkAuth: () => {
            console.log('checkAuth');
            if (keycloak.authenticated && keycloak.token) {
                const decoded: any = jwt_decode(keycloak.token);
                console.log('decoded: ');
                console.dir(decoded);
                if (decoded.resource_access && decoded.resource_access[clientID] && decoded.resource_access[clientID].roles.length > 0) {
                    return Promise.resolve();
                }
                return Promise.reject("The user has no roles in this client.");

            }
            return Promise.reject("Failed to obtain access token.");
        },
        logout: () => {
            console.log('logout');
            return keycloak.logout();
        },
        getIdentity: () => {
            console.log('getIdentity');
            if (keycloak.token) {
                const decoded: any = jwt_decode(keycloak.token);
                console.dir(decoded)
                const id = decoded.sub
                const fullName = decoded.name
                const tenantId = decoded.tenantId
                return Promise.resolve({id, fullName, tenantId});
            }
            return Promise.reject("Failed to get identity");
        },
        getPermissions: () => {
            console.log('getPermissions');
            let hasRole = false;
            if (keycloak.token) {
                const decoded: any = jwt_decode(keycloak.token);
                if (decoded && decoded.resource_access) {
                    decoded.resource_access[clientID].roles.forEach((el: string) => {
                        if (el === "admin") {
                            hasRole = true;
                            return
                        }
                    });
                }
            }
            if (hasRole) {
                return Promise.resolve(true);
            }
            return Promise.resolve(false);
        },
    });
};

export default useAuthProvider;
