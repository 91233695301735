import simpleRestProvider from 'ra-data-simple-rest';

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function (t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};

const createKeycloakProvider = (apiUrl, httpClient, tenantId) => {
    const dataProvider = simpleRestProvider(apiUrl, httpClient);
    const keycloakDataProvider = {
        ...dataProvider,
        update: (resource, params) => {
            params.data.attributes = {tenantId: tenantId};
            return dataProvider.update(resource, params).then(function () {
                return ({
                    data: {...params.data},
                });
            });
        },
        delete: function (resource, params) {
            return httpClient(apiUrl + "/" + resource + "/" + params.id, {
                method: 'DELETE',
                headers: new Headers({
                    'Content-Type': 'text/plain',
                }),
            }).then(function () {
                return ({
                    data: {...params.data},
                });
            });
        },
        create: function (resource, params) {
            params.data.attributes = {tenantId: tenantId};

            return httpClient(apiUrl + "/" + resource, {
                method: 'POST',
                body: JSON.stringify(params.data),
            }).then(function (_a) {
                var headers = _a.headers;
                var id = null;
                if (headers.has('location')) {
                    var location = headers.get('location');
                    var parts = location.split("/");
                    id = parts[parts.length - 1];
                }
                return ({
                    data: {...params.data, id: id},
                });
            });
        },
        getList: async function (resource, params) {
            var _a = params.pagination, page = _a.page, perPage = _a.perPage;
            var rangeStart = (page - 1) * perPage;

            var url = apiUrl + "/" + resource + "?first=" + rangeStart + "&max=" + perPage;
            var countUrl = apiUrl + "/" + resource + "/count";

            var countResponse = await httpClient(countUrl);

            return httpClient(url).then(function (_a) {
                var count = 0;
                if (typeof countResponse.json == 'object') {
                    count = countResponse.json.count;
                } else {
                    count = countResponse.json;
                }
                var result = {
                    data: _a.json,
                    total: count,
                };
                return result;
            });
        },
        getManyReference: async function (resource, params) {
            var _b = params.pagination, page = _b.page, perPage = _b.perPage;
            var rangeStart = (page - 1) * perPage;

            var url = apiUrl + "/" + resource + "?first=" + rangeStart + "&max=" + perPage;
            var countUrl = apiUrl + "/" + resource + "/count";

            var countResponse = await httpClient(countUrl);

            return httpClient(url).then(function (_a) {
                return {
                    data: _a.json,
                    total: countResponse.json,
                };
            });
        },
    };
    return keycloakDataProvider;
}

export default createKeycloakProvider;
